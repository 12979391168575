@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

body {
  width: 100%;
}

.body,
#root {
  height: 100%;
  overflow: hidden;
}

:root {
    --mirrar-webar-primary-color: #000000d9;
    --mirrar-webar-secondary-color: #fff;
}
.border-bottom {
  border-bottom: 2px solid black;
}

.selectedItem {
    border-width: 2px;
    border-color: var(--mirrar-webar-primary-color);
}

.card-panel-container::-webkit-scrollbar {
  display: none;
}

.card-panel-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
